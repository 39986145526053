import React from 'react'

import Button from '@/components/ui/Button/Button'
import Card from '@/components/Header/components/Menu/components/Card/Card'

import classes from './CardList.module.scss'

export default function CardList({
  heading,
  list,
  actionButton,
}: {
  heading: string
  list: any[]
  actionButton: any
}) {
  return (
    <div className={classes.root}>
      <p className={classes.heading}>{heading}</p>
      <div className={classes.list}>
        {list.map((item, i) => {
          return <Card key={i} item={item} />
        })}
        {actionButton && (
          <div className={classes.button}>
            <Button text={actionButton.title} Icon={actionButton.Icon} />
          </div>
        )}
      </div>
    </div>
  )
}
