import React from 'react'
import Link from 'next/link'

import DemoSection from '../DemoSection/DemoSection'

import classes from './LinkGroups.module.scss'

const LinkGroups = ({ items, onClose }: { items: any[]; onClose: () => void }) => {
  return (
    <div className={classes.root}>
      {items.map(({ title, list }, i) => {
        let groupClasses = classes.group

        if (i % 2 === 0) {
          groupClasses += ` ${classes.group_left}`
        } else {
          groupClasses += ` ${classes.group_right}`
        }
        return (
          <div key={i} className={groupClasses}>
            <b className={classes.title}>{title}</b>
            <div className={classes.items}>
              {list.map((link, i) => {
                const isDisabled = !link.href || link.href === '' || link.href === '#'
                let itemClasses = classes.item

                if (isDisabled) {
                  itemClasses += ` ${classes.item_disabled}`
                }

                return (
                  <Link key={i} href={link.href} onClick={onClose} className={itemClasses}>
                    {link.name}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      })}
      <div className={`${classes.group} ${classes.group_right}`}>
        <DemoSection />
      </div>
    </div>
  )
}

export default LinkGroups
