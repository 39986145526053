import SubmitIcon from '@/assets/icons/arrows/arrow-submit.svg'
import IconPencil from '@/assets/icons/header/nav/pencil.svg'
import IconPaper from '@/assets/icons/header/nav/paper.svg'
import IconMegaphone from '@/assets/icons/header/nav/megaphone.svg'

export const BY_INDUSTRY = {
  title: 'By Industry:',
  list: [
    {
      name: 'Camps',
      href: '/landings/service-providers/industry/camps',
    },
    {
      name: 'After-School Programs',
      href: '/landings/service-providers/industry/after-school',
    },
    {
      name: 'Park & Rec',
      href: '/landings/service-providers/industry/park-and-rec',
    },
    {
      name: '3rd Party Providers',
      href: '/landings/service-providers/industry/3rd-party-providers',
    },
    {
      name: 'Franchise',
      href: '/landings/service-providers/industry/franchise',
    },
  ],
}

const linkGroups = [
  BY_INDUSTRY,
  {
    title: 'By Product:',
    list: [
      {
        name: 'Online Registration',
        href: '#',
      },
      {
        name: 'Widgets',
        href: '#',
      },
      {
        name: 'Parent Portal',
        href: '#',
      },
      {
        name: 'Forms Management',
        href: '#',
      },
      {
        name: 'Memberships',
        href: '#',
      },
      {
        name: 'Groups',
        href: '#',
      },
      {
        name: 'Communication',
        href: '#',
      },
      {
        name: 'Analytics',
        href: '#',
      },
      {
        name: 'Reporting',
        href: '#',
      },
      {
        name: 'Digital Check In/Out',
        href: '#',
      },
    ],
  },
  {
    title: 'I Am Looking To:',
    list: [
      {
        name: 'Get my business listed',
        href: '#',
        Icon: IconPaper,
      },
      {
        name: 'Do marketing',
        href: '#',
        Icon: IconMegaphone,
      },
      {
        name: 'Simplify Registrations',
        href: '#',
        Icon: IconPencil,
      },
    ],
  },
]

const demoCardListSecond = {
  heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  list: [
    {
      title: 'Marketplаce',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-marketplace.webp',
        alt: 'Abstract representation of a marketplace with interconnected icons related to different industries and services.',
      },
    },
    {
      title: 'Family Center',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-family-center.webp',
        alt: "Brightly decorated children's play area with green walls and playful furniture.",
      },
    },
    {
      title: 'Newsletters',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-news-letters.webp',
        alt: 'Printed newsletters showcasing various articles and images, highlighting the importance of regular updates.',
      },
    },
    {
      title: 'From the Editor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-from-the-editor.webp',
        alt: 'Close-up of a newspaper article with people featured on the front page.',
      },
    },
  ],
}

const demoCardList = {
  heading:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
  list: [
    {
      title: 'Marketplаce',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-marketplace.webp',
        alt: 'Abstract representation of a marketplace with interconnected icons related to different industries and services.',
      },
    },
    {
      title: 'Family Center',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-family-center.webp',
        alt: "Brightly decorated children's play area with green walls and playful furniture.",
      },
    },
    {
      title: 'Newsletters',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-news-letters.webp',
        alt: 'Printed newsletters showcasing various articles and images, highlighting the importance of regular updates.',
      },
    },
    {
      title: 'From the Editor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-from-the-editor.webp',
        alt: 'Close-up of a newspaper article with people featured on the front page.',
      },
    },
    {
      title: 'Become a Contributor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-become-a-contributor.webp',
        alt: 'Woman working on a laptop in a modern cafe, illustrating the concept of contributing content.',
      },
    },
    {
      title: 'Affiliate Program',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/affilate-program.webp',
        alt: 'Diagram showing the affiliate marketing model with publisher, merchant, and customer interactions.',
      },
    },
  ],
}

const demoCardListThird = {
  heading:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
  list: [
    {
      title: 'Marketplаce',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-marketplace.webp',
        alt: 'Abstract representation of a marketplace with interconnected icons related to different industries and services.',
      },
    },
    {
      title: 'Family Center',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-family-center.webp',
        alt: "Brightly decorated children's play area with green walls and playful furniture.",
      },
    },
    {
      title: 'Newsletters',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-news-letters.webp',
        alt: 'Printed newsletters showcasing various articles and images, highlighting the importance of regular updates.',
      },
    },
    {
      title: 'From the Editor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-from-the-editor.webp',
        alt: 'Close-up of a newspaper article with people featured on the front page.',
      },
    },
    {
      title: 'Become a Contributor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/img-become-a-contributor.webp',
        alt: 'Woman working on a laptop in a modern cafe, illustrating the concept of contributing content.',
      },
    },
    {
      title: 'Affiliate Program',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit .Nunc',
      image: {
        src: '/redesign/assets/images/nav/cards/affilate-program.webp',
        alt: 'Diagram showing the affiliate marketing model with publisher, merchant, and customer interactions.',
      },
    },
  ],
}

export const MENU_LIST = [
  {
    id: 'menu-button-service-providers',
    name: 'Service Providers',
    linkGroups: linkGroups,
    menuId: 'menu-service-providers',
  },
  {
    id: 'menu-button-parents',
    name: 'Parents',
    cardList: demoCardList,
    menuId: 'menu-parents',
    disabled: true,
  },
  {
    id: 'menu-button-pricing',
    name: 'Pricing',
    link: '#',
    disabled: true,
  },
  {
    id: 'menu-button-company',
    name: 'Company',
    cardList: demoCardListSecond,
    menuId: 'menu-company',
    disabled: true,
  },
  {
    id: 'menu-button-resources',
    name: 'Resources',
    menuId: 'menu-resources',
    cardList: {
      ...demoCardListThird,
      list: [...demoCardListThird.list],
      actionButton: {
        title: 'Help Center',
        href: '#',
        Icon: SubmitIcon,
      },
      phone: {
        raw: '+1222222222',
        formatted: '+1 222 222 222',
      },
    },
    disabled: true,
  },
  {
    id: 'menu-button-investors',
    name: 'Investors',
    menuId: 'menu-investors',
    cardList: {
      ...demoCardListSecond,
      list: [...demoCardListSecond.list],
      actionButton: {
        title: 'Help Center',
        href: '#',
        Icon: SubmitIcon,
      },
    },
    disabled: true,
  },
]
