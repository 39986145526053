import React from 'react'

import classes from './Button.module.scss'
import { TUserMenu } from '@/components/Header/components/UserMenu/UserMenu'

type TButton = {
  onClick: (event?: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  isActive?: boolean
} & TUserMenu

export default function Button({ onClick, children, isActive, styles, theme }: TButton) {
  let rootClasses = classes.root

  if (isActive) {
    rootClasses += ` ${classes.root_active}`
  }

  if (theme === 'light') {
    rootClasses += ` ${classes.root_light}`
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className={rootClasses}
      style={styles ? { ...styles } : {}}
    >
      {children}
    </button>
  )
}
