import React from 'react'
import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import List from './components/List/List'
import { MENU_LIST } from '@/components/Header/components/Menu/constants'

const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    backgroundColor: 'var(--color-background)',
    width: '100%',
  },
})

type TDrawer = {
  isOpen: boolean
  onClose: () => void
}

export default function DrawerComponent({ isOpen, onClose }: TDrawer) {
  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <div className="container">
        <Header onCloseClick={onClose} />
      </div>
      <List items={MENU_LIST} onClose={onClose} />
      <div className="container">
        <Footer />
      </div>
    </StyledDrawer>
  )
}
