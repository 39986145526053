import React, { useState } from 'react'

import Accordion from '@/components/ui/Accordion/Accordion'
import LinkGroupsContent from './components/LinkGroups/LinkGroups'
import CardListContent from './components/CardList/CardList'

type TList = {
  items: any[]
  onClose: () => void
}

export default function List({ items, onClose }: TList) {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (id: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? id : false)
  }

  return items.map((item, i) => {
    let Content: any = null
    let link

    if (item.linkGroups) {
      Content = LinkGroupsContent({ items: item.linkGroups, onClose })
    }

    if (item.cardList) {
      Content = CardListContent(item.cardList)
    }

    if (item.link) {
      link = item.link
    }

    return (
      <Accordion
        key={i}
        title={item.name}
        content={Content}
        link={link}
        id={item.id}
        onChange={handleChange}
        expanded={expanded === item.id}
        disabled={item.disabled}
      />
    )
  })
}
