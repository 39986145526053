import React from 'react'
import { MENU_LIST } from '@/components/Header/components/Menu/constants'

import Menu from '@mui/material/Menu'
import { styled } from '@mui/material/styles'

import LinkGroups from './components/LinkGroups/LinkGroups'
import CardList from './components/CardList/CardList'

import IconArrowDown from '@/assets/icons/arrows/chevron-bottom-9x7.svg'

import classes from './NavBar.module.scss'

type TNavBar = {}

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: 'var(--color-background)',
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
    maxWidth: '100%',
    left: 0,
    right: 0,
    '& .MuiList-root': {
      padding: 0,
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 32, 75, 0.25)',
  },
})

export default function NavBar({}: TNavBar) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [activeButton, setActiveButton] = React.useState<null | string>(null)
  const [menuContent, setMenuContent] = React.useState<React.ReactNode>(null)
  const open = Boolean(anchorEl)

  const handleClick = (menuItem) => {
    const itemId = menuItem.id
    const headerEl = document?.getElementById('header-main')

    setAnchorEl(headerEl)
    setActiveButton(itemId)

    if (menuItem && menuItem.linkGroups) {
      setMenuContent(<LinkGroups list={menuItem.linkGroups} onClose={handleClose} />)
    } else if (menuItem && menuItem.cardList) {
      setMenuContent(<CardList content={menuItem.cardList} />)
    } else if (itemId !== 'menu-button-pricing') {
      setMenuContent(<span>{itemId}</span>)
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActiveButton(null)
    setMenuContent(null)
  }

  return (
    <div className={classes.root}>
      {MENU_LIST.map((item, i) => {
        const isActive = activeButton === item.id
        const buttonClasses = `${classes.button} ${isActive ? classes.button_active : ''}`

        return (
          <div key={i}>
            <button
              id={item.id}
              aria-controls={open ? item.menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => handleClick(item)}
              className={buttonClasses}
              disabled={item.disabled}
            >
              {item.name}
              {!item.link && <IconArrowDown />}
            </button>
            {menuContent && (
              <StyledMenu
                id={item.menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': item.id,
                }}
                marginThreshold={0}
              >
                {menuContent}
              </StyledMenu>
            )}
          </div>
        )
      })}
    </div>
  )
}
