import React from 'react'

import classes from './Footer.module.scss'
import IconInstagram from '@/assets/icons/social/inst.svg'

import Phone from '@/components/Header/components/Menu/components/Drawer/components/Phone/Phone'

type TFooter = {}

export default function Footer({}: TFooter) {
  const tel = {
    raw: '+1222222222',
    formatted: '+1 222 222 222',
  }
  return (
    <div className={classes.root}>
      <Phone phone={tel} />
      <div className={classes.socials}>
        <div className={classes.icon}>
          <IconInstagram />
        </div>
        <div className={classes.icon}>
          <IconInstagram />
        </div>
        <div className={classes.icon}>
          <IconInstagram />
        </div>
      </div>
    </div>
  )
}
