import React from 'react'

import IconPhone from '@/assets/icons/header/menu/phone-22x22.svg'

import classes from './Phone.module.scss'

type TPhone = {
  phone: {
    raw: string
    formatted: string
  }
}

export default function Phone({ phone }: TPhone) {
  return (
    <div className={classes.root}>
      <IconPhone />
      <a
        className={classes.link}
        href={`tel:${phone.raw}`}
        // onClick={handlePhoneClick}
      >
        {phone.formatted}
      </a>
    </div>
  )
}
