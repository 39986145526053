import React from 'react'
import Image from 'next/image'

import IconUser from '@/assets/icons/header/menu/user'
import { useAuth } from '@/context/AuthContext'

import classes from './UserAvatar.module.scss'
import { TUserMenu } from '@/components/Header/components/UserMenu/UserMenu'

type TUserAvatar = {} & TUserMenu

export default function UserAvatar({ theme }: TUserAvatar) {
  const { user } = useAuth()

  let iconColor = '#636571'

  if (theme === 'light') {
    iconColor = '#ffffff'
  }

  if (user) {
    if (user.image) {
      return <Image src={user.image} alt={`${user.name} avatar`} width={48} height={48} />
    } else if (user.first && user.last) {
      const firstLetters = `${user.first[0]}${user.last[0]}`
      return <span className={classes.letters}>{`${firstLetters.toUpperCase()}`}</span>
    }
  }
  return <IconUser fill={iconColor} />
}
