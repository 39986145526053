import React from 'react'

import DemoSection from '@/components/Header/components/Menu/components/Drawer/components/List/components/DemoSection/DemoSection'
import Button from '@/components/ui/Button/Button'
import List from '@/components/Header/components/Menu/NavBar/components/LinkGroups/components/List/List'

import SubmitIcon from '@/assets/icons/arrows/arrow-submit.svg'

import classes from './LinkGroups.module.scss'
import listClasses from './components/List/List.module.scss'

type TContentLinks = { list: any; onClose: () => void }

export default function LinkGroups({ list, onClose }: TContentLinks) {
  const [firstSection, ...remainingSections] = list

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <List {...firstSection} onClose={onClose} />
      </div>
      <div className={classes.right}>
        <div className={classes.content}>
          {remainingSections.map((section, i) => {
            return <List key={i} {...section} right={true} />
          })}
          <div className={listClasses.root}>
            <DemoSection />
          </div>
        </div>
        <div className={classes.footer}>
          <Button text="Explore all business solutions" Icon={SubmitIcon} />
        </div>
      </div>
    </div>
  )
}
