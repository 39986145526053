import React from 'react'
import Link from 'next/link'

import ImageMobileSvg from '@/assets/images/media-noodles-plate-358-163.svg'
import ImageTabletSvg from '@/assets/images/media-noodles-plate-428-163.svg'
import ImageDesktopSvg from '@/assets/images/media-noodles-plate-208-163.svg'

import classes from './DemoSection.module.scss'
import useViewport from '@/hooks/useViewport'

type TDemoSection = {}

export default function DemoSection({}: TDemoSection) {
  const viewport = useViewport({ isMobile: true })

  return (
    <div className={classes.root}>
      {viewport === 'mobile' && <ImageMobileSvg />}
      {viewport === 'tablet' && <ImageTabletSvg />}
      {viewport === 'desktop' && <ImageDesktopSvg />}
      <Link href="#" className={classes.link}>
        Get a demo section
      </Link>
    </div>
  )
}
