import React from 'react'

import Logo from '@/components/Header/components/Logo/Logo'
import Button from '@/components/Header/components/Button/Button'
import IconClose from '@/assets/icons/header/menu/close.svg'

import classes from './Header.module.scss'

type THeader = {
  onCloseClick: () => void
}

export default function Header({ onCloseClick }: THeader) {
  return (
    <div className={classes.root}>
      <Logo viewport={'desktop'} />
      <Button onClick={onCloseClick}>
        <IconClose />
      </Button>
    </div>
  )
}
