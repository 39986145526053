import React from 'react'
import Link from 'next/link'

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { styled } from '@mui/material/styles'

import Icon from '@/assets/icons/arrows/chevron-bottom-bigger.svg'

const padding = '16px'

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    slotProps={{ transition: { unmountOnExit: true } }}
    {...props}
  />
))({
  '& .Mui-expanded': {
    backgroundColor: 'var(--color-bg-dropdown--active)',
  },
  '&.MuiAccordion-root.Mui-disabled': {
    backgroundColor: 'transparent',
    '& a': {
      pointerEvents: 'none',
      opacity: 0.38,
    },
  },
  '&:before': {
    width: `calc(100% - (${padding} * 2))`,
    left: padding,
    backgroundColor: 'var(--color-border)',
    bottom: '-1px',
    top: 'unset',
    zIndex: 1,
  },
})

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={props.expandIcon} {...props} />
))({
  padding: padding,
  paddingRight: `calc(${padding} * 2)`,
  backgroundColor: 'var(--color-background)',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    fontSize: 'var(--font-size-description)',
    lineHeight: 'var(--line-height-description)',
    color: 'var(--color-text-description-main)',
  },
})

const StyledAccordionDetails = styled(MuiAccordionDetails)({
  padding: `20px ${padding} 40px ${padding}`,
  // $viewport-tablet
  '@media (max-width: 834px)': {
    padding: `calc(${padding} * 2.5) calc(${padding} * 2.5) 60px calc(${padding} * 2.5)`,
  },
})

const StyledLink = styled(Link)({
  padding: padding,
  display: 'inline-block',
  width: '100%',
  boxSizing: 'border-box',
  textDecoration: 'none',
  fontSize: 'var(--font-size-description)',
  lineHeight: 'var(--line-height-description)',
  color: 'var(--color-text-description-main)',
  '&:active': {
    backgroundColor: 'var(--color-bg-dropdown--active)',
  },
  '&:hover': {
    backgroundColor: 'var(--color-bg-dropdown--active)',
  },
})

type TAccordion = {
  title: string
  content?: React.ReactNode
  link?: string
  id: string
  expanded: boolean
  onChange: any
  disabled?: boolean
}

function AccordionComponent({
  title,
  content,
  link,
  expanded,
  onChange,
  id,
  disabled,
}: TAccordion) {
  return (
    <StyledAccordion expanded={expanded} onChange={onChange(id)} disabled={disabled}>
      {link ? (
        <StyledLink href={link}>{title}</StyledLink>
      ) : (
        <StyledAccordionSummary expandIcon={content ? <Icon /> : null}>
          {title}
        </StyledAccordionSummary>
      )}
      {content && <StyledAccordionDetails>{content}</StyledAccordionDetails>}
    </StyledAccordion>
  )
}

export default AccordionComponent
