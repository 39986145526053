'use client'
import React, { useState } from 'react'

import NavButton from '@/components/Header/components/Button/Button'
import Drawer from './components/Drawer/Drawer'

import IconBurger from '@/assets/icons/header/menu/burger.svg'
import IconClose from '@/assets/icons/header/menu/close.svg'

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <NavButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <IconClose /> : <IconBurger />}
      </NavButton>
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

export default MobileMenu
