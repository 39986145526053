import React from 'react'

import Card from '@/components/Header/components/Menu/components/Card/Card'
import Button from '@/components/ui/Button/Button'

import IconPhone from '@/assets/icons/header/menu/phone-12x13.svg'

import classes from './CardList.module.scss'

type TCardList = { content: any }

export default function CardList({ content }: TCardList) {
  const { heading, list, actionButton, phone } = content

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <p className={classes.heading}>{heading}</p>
        {phone && (
          <div className={classes.phone}>
            <IconPhone />
            <a
              className={classes.link}
              href={`tel:${phone.raw}`}
              // onClick={handlePhoneClick}
            >
              {phone.formatted}
            </a>
          </div>
        )}
        {actionButton && (
          <div className={classes.button}>
            <Button
              text={actionButton.title}
              Icon={actionButton.Icon}
              // disabled={true}
            />
          </div>
        )}
      </div>
      <div className={classes.right}>
        {list.map((item, i) => {
          return <Card key={i} item={item} />
        })}
      </div>
    </div>
  )
}
