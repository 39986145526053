import React from 'react'
import Image from 'next/image'

import classes from './Card.module.scss'

type TCard = {
  item: {
    image: string
    title: string
    text: string
  }
}

export default function Card({ item }: TCard) {
  let image
  if (item?.image) {
    image = item?.image
  }
  return (
    <div className={classes.root}>
      {image && <Image alt={image.alt} src={image.src} width={114} height={73} />}
      <div className={classes.content}>
        <b className={classes.title}>{item.title}</b>
        <p className={classes.text}>{item.text}</p>
      </div>
    </div>
  )
}
