import React from 'react'

import Link from 'next/link'

import classes from './List.module.scss'

type TList = {
  title: string
  list: any[]
  right?: boolean
  onClose: () => void
}

export default function List({ title, list, right, onClose }: TList) {
  let rootClasses = classes.root

  if (right) {
    rootClasses += ` ${classes.root_right}`
  }

  const handleClose = () => {
    setTimeout(() => {
      onClose()
    }, 100)
  }

  return (
    <div className={rootClasses}>
      <b className={classes.title}>{title}</b>
      <div className={classes.list}>
        {list.map((item, i) => {
          const disabled = !item.href || item.href === '#'
          let className = classes.link

          if (disabled) {
            className += ` ${classes.link_disabled}`
          }

          return (
            <Link key={i} href={item.href} onClick={handleClose} className={className}>
              {item.Icon && <item.Icon />}
              <span>{item.name}</span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
